import React, { useRef, useState } from "react";
import { useInput } from "../../hooks/useInput";
import { Container } from "../../styles/UI";
import axios from "axios";

import Layout from "../../components/Layout";
import FormInput from "../../DesignSystem/FormInput";
import Button from "../../DesignSystem/Button";
import StyledLink from "../../DesignSystem/StyledLink";
import Textarea from "../../DesignSystem/Textarea";
import QuestionWrapper from "../../components/QuestionWrapper";
import Modal from "../../DesignSystem/Modal";

import telIcon from "../../images/contato/phone_in_talk.svg";
import mappinIcon from "../../images/contato/map-pin-2-fill.svg";
import mailIcon from "../../images/contato/email.svg";
import clockIcon from "../../images/contato/timer-2-fill.svg";

import ChatCheck from "../../icons/chat-check-line.inline.svg";


import * as S from "./style";
import Seo from "../../components/Seo";
import mixpanel from "mixpanel-browser";

export default function Contato({ questions, message }) {
  const client = axios.create({
    baseURL: `https://taxcellicenseserver2.azurewebsites.net/`,
    headers: {
      "Content-Type": `application/x-www-form-urlencoded`,
    },
  });

  const messageDefault = () => {
    mixpanel.init("ac2da5c424bbdb8fbcac0d565783254a");
    if (!message) return "";
    if (message.msg === "demonstracao") {
      mixpanel.track("Comecou contato pra demonstracao", {
        produto: message?.produto === "taxsheets" ? "TaxSheets" : "TaxDashs",
      });
      return `Olá! Gostaria de agendar uma demonstração do ${
        message?.produto === "taxsheets" ? "TaxSheets" : "TaxDashs"
      }`;
    }
    if (message.msg === "proposta") {
      mixpanel.track("Pediu proposta do", {
        produto: message?.produto === "taxsheets" ? "TaxSheets" : "TaxDashs",
      });
      return `Olá! Gostaria de saber valores do ${
        message?.produto === "taxsheets" ? "TaxSheets" : "TaxDashs"
      }`;
    }
    if (message.msg === "boleto") {
      mixpanel.track("Comecou compra por boleto");
      return `Olá! Gostaria de comprar ${message.qtd} ${
        message.qtd > 1 ? "licenças" : "licença"
      } ${message.licenca} por boleto.`;
    }
    if (message.msg === "corporativa") {
      mixpanel.track("Interesse licença corporativa");
      return `Olá! Gostaria de obter mais informações sobre a licença corporativa/servidor.`;
    }

    return "";
  };

  const {
    value: email,
    isInvalid: isInvalidEmail,
    bind: bindEmail,
    reset: resetEmail,
  } = useInput("", "email");
  const {
    value: nome,
    isInvalid: isInvalidNome,
    bind: bindNome,
    reset: resetNome,
  } = useInput("");
  const {
    value: tel,
    isInvalid: isInvalidTel,
    bind: bindTel,
    reset: resetTel,
  } = useInput("", "tel");
  const {
    value: msg,
    isInvalid: isInvalidMsg,
    bind: bindMsg,
    reset: resetMsg,
  } = useInput(messageDefault);

  const [isVisible, setIsVisible] = useState(false);

  const [isSending, setIsSending] = useState(false);

  const modalRef = useRef();

  const sendForm = () => {
    if (
      email === "" ||
      isInvalidEmail ||
      nome === "" ||
      isInvalidNome ||
      tel === "" ||
      isInvalidTel ||
      msg === "" ||
      isInvalidMsg
    ) {
      alert("Preencha todos os campos para enviar a mensagem");
      return;
    }

    if (isSending) return;

    setIsSending(true);

    console.log(nome, email, tel, msg);

    const form = new URLSearchParams();

    form.append("source", "formulario_contato");
    form.append("email", email);
    form.append("mensagem", msg);
    form.append("telefone", tel);
    form.append("nome", nome);

    client
      .post(`/ContatoEmail.aspx`, form)
      .then((resp) => {
        //modal de mensagem enviada
        modalRef.current.openModal();

        setIsSending(false);
        console.log(resp);
      })
      .catch((err) => {
        setIsSending(false);
        alert("Erro ao enviar a mensagem");
        console.log(err);
      })
      .finally(() => {
        resetEmail();
        resetNome();
        resetTel();
        resetMsg();
      });
  };

  const handleDisplayChange = (display) => {
    setIsVisible(display);
  };

  return (
    <Layout>
      <Seo title="Contato" />
      <Modal onDisplayChange={handleDisplayChange} ref={modalRef}>
        <S.MessageSent>
          <ChatCheck />
          <h4>Mensagem enviada!</h4>
          <p>
            Obrigado por sua mensagem! Nós retornaremos o contato por email em
            até 1 dia útil
          </p>
        </S.MessageSent>
      </Modal>
      <S.Hero>
        <Container>
          <div className="cta">
            <h1>Fale conosco</h1>
            <p>
              Para dúvidas ou sugestões, envie uma mensagem preenchendo os
              campos abaixo. Será um prazer te ajudar!
            </p>
          </div>
          <div className="illustration">
          </div>
        </Container>
      </S.Hero>
      <S.Contato>
        <Container>
          <div className="form" id="form-contato">
            <h2>Deixe sua mensagem que entraremos em contato</h2>
            <FormInput
              required
              name="nome"
              label="Nome"
              mt={16}
              placeholder="Nome Sobrenome"
              error={isInvalidNome}
              errorMsg="Campo obrigatório"
              {...bindNome}
            />
            <FormInput
              required
              name="email"
              label="Email"
              type="email"
              mt={16}
              placeholder="nome@email.com"
              error={isInvalidEmail}
              errorMsg="Digite um email válido para continuar"
              {...bindEmail}
            />
            <FormInput
              required
              name="tel"
              type="tel"
              label="Telefone"
              mt={16}
              placeholder=""
              error={isInvalidTel}
              errorMsg="Digite um telefone válido para continuar"
              {...bindTel}
            />
            <Textarea
              required
              name="mensagem"
              label="Mensagem"
              mt={16}
              error={isInvalidMsg}
              errorMsg="Campo obrigatório"
              {...bindMsg}
            />
            <Button fluid fullWidth center mt={32} onClick={sendForm}>
              {isSending ? "Enviando..." : "Enviar Mensagem"}
            </Button>
          </div>
          <div className="info">
            <div className="section">
              <div className="icon">
                <img src={telIcon} alt="Ícone de telefone" />
              </div>
              <div>
                <p className="label">Telefone:</p>
                <p className="dado">(11) 5026-7387</p>
              </div>
            </div>
            <div className="section">
              <div className="icon">
                <img src={mappinIcon} alt="Ícone de mapa" />
              </div>
              <div>
                <p className="label">Endereço:</p>
                <p className="dado">
                  R. da Consolação, 2302 <br />
                  Consolação - São Paulo
                </p>
              </div>
            </div>
            <div className="section">
              <div className="icon">
                <img src={mailIcon} alt="Ícone de email" />
              </div>
              <div>
                <p className="label">Email:</p>
                <p className="dado">
                  contato@taxcel.com.br <br />
                  suporte@taxcel.com.br
                </p>
              </div>
            </div>
            <div className="section">
              <div className="icon">
                <img src={clockIcon} alt="Ícone de relógio" />
              </div>
              <div>
                <p className="label">Atendimento:</p>
                <p className="dado">De segunda a sexta, das 9h às 17h</p>
              </div>
            </div>
          </div>
        </Container>
      </S.Contato>
      <S.Faq>
        <Container>
          <h5 className="title">Dúvidas frequentes</h5>
          <QuestionWrapper questions={questions} />
          <p className="duvidas">
            Mais dúvidas?{" "}
            <StyledLink
              external
              to="https://taxceladdins.zendesk.com/hc/pt-br"
              arrow
              color="laranja"
            >
              Acesse nosso Portal de Ajuda
            </StyledLink>
          </p>
        </Container>
      </S.Faq>
    </Layout>
  );
}
